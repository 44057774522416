import React, { useEffect, useState } from "react";
import "./EstadoGeneral.css";
import {
  selectEstadoTablasMirror,
  setLoadingPageScreen,
} from "../../features/Dashboard/Slices/dashboardSlice";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { dashboardAPI } from "../../Api/dashboardAPI";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import Button from "../../components/reusable/Button/Button";
import { toastError, toastSuccess } from "../../utils/toast";
import {
  getEstadoTablasMirror,
  postActualizarCupos,
} from "../../features/Dashboard/AsyncThunks/estadoGeneral/getEstadoTablasMirror";

function EstadoGeneral() {
  const dispatch = useAppDispatch();
  const estadoTablasMirror = useSelector(selectEstadoTablasMirror);

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };

  useEffect(() => {
    actualizarTablasMirror();
  }, []);

  const inicializarConfiguracion = async () => {
    setLoading(true);
    await dashboardAPI.inicializarConfiguracion();
    setLoading(false);
  };

  const syncInfoTablas = async () => {
    setLoading(true);
    try {
      await dashboardAPI.syncInfoTablas();
      toastSuccess("Diccionario de tablas sincronizado con éxito");
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const sincronizarSiguienteTabla = async () => {
    setLoading(true);
    await dashboardAPI.sincronizarSiguienteTabla();
    setLoading(false);
  };

  const borrarTodasLasTablas = async () => {
    setLoading(true);
    await dashboardAPI.deleteAllTables();
    setLoading(false);
  };
  const dropTables = async () => {
    setLoading(true);
    await dashboardAPI.dropAllTables();
    setLoading(false);
  };
  const actualizarTablasMirror = async () => {
    await dispatch(getEstadoTablasMirror());
  };
  const actualizarCupos = async () => {
    await dispatch(postActualizarCupos());
  };

  useEffect(() => {
    console.log("estadoTablasMirror :>> ", estadoTablasMirror);
  }, [estadoTablasMirror]);

  const populateTable = async (endpoint: string) => {
    try {
      setLoading(true);
      const result = await dashboardAPI.inicializarTablaMirror(endpoint);
      console.log("populateTable result :>> ", result);
      toastSuccess(`Se inicializó correctamente la tabla ${endpoint}`);
      actualizarTablasMirror();
    } catch (error) {
      toastError("Ha ocurrido un error inesperado...");
      console.log("error :>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const renderCustomTable = () => {
    if (!estadoTablasMirror[0]) {
      return <></>;
    }
    const titles = Object.keys(estadoTablasMirror[0]);
    const populateKeyName = "populateEndpoint";
    return (
      <div className="justify-center flex-1 mt-3 px-5">
        <div className="tableList">
          <div className="flex flex-row titleTable">
            {titles.map((title) => {
              if (title === populateKeyName) return <></>;
              else {
                return (
                  <div className="text-center border-x border-grey w-full">
                    <b>{title}</b>
                  </div>
                );
              }
            })}
            <div className="text-center w-5/12" />
          </div>
          {estadoTablasMirror?.map((item: any) => {
            const rows = titles.map((keyOfObject) => {
              if (keyOfObject === populateKeyName) {
                return (
                  <div className="w-4/12 text-center customItem">
                    <Button
                      label="Sincronizar"
                      iconName="arrow-clockwise"
                      className="w-full h-8"
                      onClick={() => populateTable(item.clave)}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="w-full text-center customItem">
                    <p>{item[keyOfObject] || ""}</p>
                  </div>
                );
              }
            });
            return <div className="itemList flex flex-row">{rows}</div>;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="estadoGeneralContainer">
      <div className="buttonsMirror">
        <Button theme="green" className="w-2/12" onClick={syncInfoTablas}>
          Sync info tablas
        </Button>
        <Button
          theme="red"
          className="ml-1 w-2/12"
          onClick={inicializarConfiguracion}
        >
          Reset Configuración
        </Button>
        <Button
          theme="blue"
          className="ml-1 w-2/12"
          onClick={sincronizarSiguienteTabla}
        >
          Sincronizar Siguiente Tabla
        </Button>
        <Button
          theme="red"
          className="ml-1 w-2/12"
          onClick={borrarTodasLasTablas}
        >
          Borrar toda la información
        </Button>
        <Button theme="red" className="ml-1 w-2/12" onClick={dropTables}>
          Elimina (DROP) todas las tablas
        </Button>
        <Button
          onClick={actualizarTablasMirror}
          className="ml-1 w-2/12"
          theme="white"
        >
          <SlIcon slot="suffix" name="arrow-counterclockwise"></SlIcon>
          Actualizar tabla
        </Button>
        <Button onClick={actualizarCupos} className="ml-1 w-2/12" theme="blue">
          <SlIcon slot="suffix" name="arrow-counterclockwise"></SlIcon>
          Actualizar Cupos
        </Button>
      </div>
      {renderCustomTable()}
    </div>
  );
}

export default EstadoGeneral;
